import { render, staticRenderFns } from "./Logo.vue?vue&type=template&id=486c9972"
import script from "./Logo.vue?vue&type=script&lang=js"
export * from "./Logo.vue?vue&type=script&lang=js"
import style0 from "./Logo.vue?vue&type=style&index=0&id=486c9972&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17.21_prettier@2.8.8_pug@3.0._uz6juasc47ngydgnl4ajsp7n4y/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports