
import gsap from 'gsap'
import { mapGetters } from 'vuex'
const tl = gsap.timeline({defaults:{ duration: 0.4,ease: "back.out(1.7)"}})
export default {
  name: 'DefaultLayout',
  data(){
    return {
      hasInited: false,
    }
  },
  computed:{
    ...mapGetters({
      sidebarOpened: 'sidebarOpened'
    }),
    isHome(){
      return this.$route.name?.includes('index')
    }
  },
  watch:{
    '$route'(){
      this.asideAnimations()
    }
  },
  mounted() {
    this.asideAnimations(true)
  },
  methods:{
    asideAnimations(hasMount){
      if(this.$route.fullPath === '/') {
        tl.fromTo(`.Layout aside`,{
          transform: hasMount ? 'translate(-100%)' : 'translate(0)'
        },{
          transform: 'translate(-100%)',
        }).then(() => {
          this.hasInited = false
        })
      }else if(!this.hasInited) {
          gsap
            .fromTo(`.Layout aside`,{
              transform: 'translate(-100%)'
            },{
              stagger: 0.1,
              transform: 'translate(0)',
            })
          gsap.fromTo('.chz-menu ul li', {
              duration: 0.1,
              translateX: "-=120%",
              opacity: 0
            },{
              stagger: 0.1,
              translateX: "0",
              opacity: 1,
              clearProps: 'all'
            })
            .then(() => {
              this.hasInited = true
            })
        }
    }
  }
}
