export const state = () => ({
  sidebarOpened: false
})

export const getters = {
  sidebarOpened: state => state.sidebarOpened
}

export const mutations = {
  openSidebar(state,payload) {
    state.sidebarOpened = payload
  }
}
export default {
  state,
  getters,
  mutations,
  strict: false,
}
