
import {mapGetters,mapMutations} from 'vuex'
export default {
  name: 'MobileHeader',
  computed:{
    ...mapGetters({
      sidebarOpened: 'sidebarOpened'
    })
  },
  methods: {
    ...mapMutations({
      openSidebar: 'openSidebar'
    }),
    sidebarToggle(){
      this.openSidebar(!this.sidebarOpened)
    }
  }
}
