
import { mapGetters } from 'vuex'
export default {
  name: 'Sidebar',
  computed:{
    ...mapGetters({
      sidebarOpened: 'sidebarOpened'
    })
  },
  methods: {
    async goHome(){
      await this.$router.push('/')
    }
  }
}
