import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6da6a86d = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _bdc9d500 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _ed9b60d8 = () => interopDefault(import('../pages/pilates.vue' /* webpackChunkName: "pages/pilates" */))
const _348a0daa = () => interopDefault(import('../pages/portfolio/index.vue' /* webpackChunkName: "pages/portfolio/index" */))
const _59555706 = () => interopDefault(import('../pages/resume.vue' /* webpackChunkName: "pages/resume" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _37f7503a = () => interopDefault(import('../pages/portfolio/_slug.vue' /* webpackChunkName: "pages/portfolio/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _6da6a86d,
    name: "about"
  }, {
    path: "/contact",
    component: _bdc9d500,
    name: "contact"
  }, {
    path: "/pilates",
    component: _ed9b60d8,
    name: "pilates"
  }, {
    path: "/portfolio",
    component: _348a0daa,
    name: "portfolio"
  }, {
    path: "/resume",
    component: _59555706,
    name: "resume"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index"
  }, {
    path: "/portfolio/:slug",
    component: _37f7503a,
    name: "portfolio-slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
