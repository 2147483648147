import { render, staticRenderFns } from "./pilates.vue?vue&type=template&id=13f7802a"
var script = {}


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17.21_prettier@2.8.8_pug@3.0._uz6juasc47ngydgnl4ajsp7n4y/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports