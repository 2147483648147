export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const Menu = () => import('../../components/Menu.vue' /* webpackChunkName: "components/menu" */).then(c => wrapFunctional(c.default || c))
export const MobileHeader = () => import('../../components/MobileHeader.vue' /* webpackChunkName: "components/mobile-header" */).then(c => wrapFunctional(c.default || c))
export const Sidebar = () => import('../../components/Sidebar.vue' /* webpackChunkName: "components/sidebar" */).then(c => wrapFunctional(c.default || c))
export const AboutTop = () => import('../../components/about/AboutTop.vue' /* webpackChunkName: "components/about-top" */).then(c => wrapFunctional(c.default || c))
export const AboutFunFacts = () => import('../../components/about/FunFacts.vue' /* webpackChunkName: "components/about-fun-facts" */).then(c => wrapFunctional(c.default || c))
export const AboutServices = () => import('../../components/about/Services.vue' /* webpackChunkName: "components/about-services" */).then(c => wrapFunctional(c.default || c))
export const PageTitle = () => import('../../components/page/PageTitle.vue' /* webpackChunkName: "components/page-title" */).then(c => wrapFunctional(c.default || c))
export const ResumeExperience = () => import('../../components/resume/Experience.vue' /* webpackChunkName: "components/resume-experience" */).then(c => wrapFunctional(c.default || c))
export const ResumeSkills = () => import('../../components/resume/Skills.vue' /* webpackChunkName: "components/resume-skills" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
